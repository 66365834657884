import React, { Component } from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { SiteTitle } from "../components/elements/SiteTitle";

import { Footer } from "../components/collections/Footer";
import { LogoPanel } from "../panels/logoPanel";
import { Header } from "../components/collections/Header";
import { Builder } from "../components/collections/Builder";

class Index extends Component {
  render() {
    const { acf } = this.props.data.wordpressPage;

    const header = {
      textColor: acf.header_text_color,
      isParallax: acf.header_parallax,
      image: {
        ...acf.header_image_bg,
      },
      blurredImage: acf.header_image_blurred,
      video: acf.header_video,
      backgroundType: acf.header_background,
      title: this.props.data.wordpressPage.title,
    };

    if (
      acf.header_image_bg &&
      acf.header_image_bg.localFile &&
      acf.header_image_bg.localFile.childImageSharp
    ) {
      header.image.src =
        acf.header_image_bg.localFile.childImageSharp.original.src;
    }

    const { content_builder_page } = this.props.data.wordpressPage.acf;

    return (
      <Layout>
        <SiteTitle>Portfolio</SiteTitle>
        <Header {...header} />
        <Builder content={content_builder_page} />
        <LogoPanel />
        <Footer />
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query portfolioPageQuery {
    wordpressPage(slug: { eq: "portfolio" }) {
      slug
      title
      content
      acf {
        header_parallax
        header_image_bg {
          alt_text
          source_url
          title
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        header_image_blurred
        header_image_front
        header_text_colour
        header_video {
          source_url
          alt_text
          title
        }
        header_background
        page_heading
        page_text_content
        content_builder_page {
          __typename
          ... on WordPressAcf_text_block {
            id
            text
            text_size
            heading
          }
          ... on WordPressAcf_grid_content {
            id
            text
            text_width
            heading
            layout
            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_accordion {
            id
            panel {
              content
              heading
            }
          }
        }
      }
    }
  }
`;
